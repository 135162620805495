
import ApexCharts from "@node_modules/apexcharts/dist/apexcharts.min.js";

import noUiSlider from "@node_modules/nouislider/dist/nouislider.js";
import autosize from "@node_modules/autosize/dist/autosize.min.js";
import Dropzone from "@node_modules/dropzone/dist/dropzone.js";


import { CountUp } from "@node_modules/countup.js/dist/countUp.min.js";
import * as tnsBundle from "tiny-slider";
import SmoothScroll from "@node_modules/smooth-scroll/dist/smooth-scroll.js";
import lozad from '@node_modules/lozad/dist/lozad.min.js';

window.noUiSlider = noUiSlider;
window.autosize = autosize;
window.ApexCharts = ApexCharts;
window.Dropzone = Dropzone;
window.CountUp = CountUp;
window.tns = tnsBundle.tns;
window.SmoothScroll = SmoothScroll;
window.lozad = lozad;
